import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {  MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { ViewQuoteComponent } from './pages/view-quote/view-quote.component';
import { NewQuoteComponent } from './pages/new-quote/new-quote.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { PlanSelectionComponent } from './parts/plan-selection/plan-selection.component';
import { ViewCoverageDialogComponent } from './parts/view-coverage/view-coverage.component';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { QuotePathSelectionComponent } from './pages/quote-path-selection/quote-path-selection.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { EntityEditModalComponent } from './pages/entity-edit/entity-edit-modal.component';
import { EntityFindModalComponent } from './pages/entity-find/entity-find-modal.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { SendQuoteDialogComponent } from './pages/send-quote-dialog/send-quote-dialog.component';
import { StartupService } from './startup.service';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RegisterComponent } from './pages/register/register.component';
import { DoughnutComponent } from './parts/doughnut/doughnut.component';
import { AppRoutingModule } from './app-routing.module';
import { SwapableImageModule } from '@cogent/client/shared/components/misc/swapable-image/swapable-image.module';
import { CommonModule } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BasicEntityEntryModule } from '@cogent/client/shared/components/entities/basic-entity-entry/basic-entity-entry.module';
import { AddressEditorComponent } from '@cogent/client/shared/components/misc/address-editor/address-editor.component';
import { DatePickerWrapperComponent } from '@cogent/client/shared/components/misc/date-picker/date-picker-wrapper.component';
import { PlanOfferingsModule } from '@cogent/client/shared/components/plans-and-coverage/plan-offerings1/plan-offerings.module';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { CircleWidgetSmallModule } from '@cogent/client/shared/components/data-visualization/circle-widget-small/circle-widget-small.module';
import { BasicEntitySummaryModule } from '@cogent/client/shared/components/entities/basic-entity-summary/basic-entity-summary.module';
import { ViewPolicyComponent } from './pages/view-policy/view-policy.component';
import { NewOrderFrameComponent } from './pages/new-order-frame/new-order-frame.component';
import { RequestServiceComponent } from './pages/request-service/request-service.component';
import { TakePaymentComponent } from './pages/take-payment/take-payment.component';
// import { UpgradeCoverageComponent } from './pages/upgrade-coverage/upgrade-coverage.component';
import { ChangeCoverageComponent } from './pages/change-coverage/change-coverage.component';
import { ApiFailureComponent } from '@cogent/client/shared/components/api-failure/api-failure.component';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';

export function startupServiceFactory(startupService: StartupService) {
    return () => startupService.load();
}




@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ViewQuoteComponent,
        NewQuoteComponent,
        // PlanSelectionComponent,
        ViewCoverageDialogComponent,
        QuotePathSelectionComponent,
        OrdersComponent,
        EntityEditModalComponent,
        EntityFindModalComponent,
        // BasicEntityEntryComponent,
        SettingsComponent,
        ChangePasswordComponent,
        SendQuoteDialogComponent,
        ResetPasswordComponent,
        RegisterComponent,
        DoughnutComponent,
        ViewPolicyComponent,
        NewOrderFrameComponent,
        RequestServiceComponent,
        TakePaymentComponent,
        // UpgradeCoverageComponent,
        ChangeCoverageComponent,
    ],
    imports: [
        BrowserModule,
        // RouterModule.forRoot(appRoutes),
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatListModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatButtonModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSnackBarModule,
        MatRadioModule,
        NgxMaskDirective,
        ReactiveFormsModule,
        MatAutocompleteModule,
        DisplayTotalModule,
        DatePickerWrapperComponent,
        AddressEditorComponent,
        BasicEntitySummaryModule,
        SwapableImageModule,
        BasicEntityEntryModule,
        CheckCircleAnimatedComponent,
        PaymentMethodEntryModule,
        PaymentMethodEntryComponent,
        PlanOfferingsModule,
        CircleWidgetSmallModule,
        ApiFailureComponent,
    ],
    providers: [
        provideNgxMask(),
        { provide: 'authConfig', useValue: environment.authConfig },
        // { provide: 'apiConfig', useValue: environment.apiConfig },
        StartupService,
        {
            // Provider for APP_INITIALIZER
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true
        },
        CustomerRepositoryService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
